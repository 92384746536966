import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from '@wix/yoshi-flow-editor';
import { SECTION_POST_LIST } from '@wix/communities-blog-client-common';
import AppLoaded from '../../../common/components/app-loaded';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { connect } from '../../../common/components/runtime-context';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import {
  getIsCreatedWithResponsiveEditor,
  getLayoutMargins,
  getUseMobileDesignSettings,
  getUseMobileLayoutSettings,
} from '../../../common/selectors/app-settings-selectors';
import { getLayoutType } from '../../../common/selectors/layout-selectors';
import { isInWix } from '../../../common/services/is-in-wix';
import {
  getLanguage,
  getIsMobile,
  isEditor,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import PostList from '../post-list';
import styles from './app-root.scss';

class AppRoot extends React.Component {
  componentDidMount() {
    if (!isInWix()) {
      document.documentElement.classList.add('enable-scroll');
    }
    if (this.props.isEditor && this.props.createdWithResponsiveEditor) {
      document.body.style.height = 'auto';
    }
  }

  render() {
    const { padding, useMobileDesign, i18n, language, translations } = this.props;
    i18n.addResourceBundle(language, 'translation', translations, true, false);

    return (
      <ResponsiveListener dataHook="post-list-root">
        <div
          style={{ padding }}
          className={classNames(
            styles.root,
            useMobileDesign ? styles.mobileBaseStyles : styles.baseStyles,
          )}
        >
          <PostList />
          <AppLoaded />
        </div>
      </ResponsiveListener>
    );
  }
}

AppRoot.propTypes = {
  language: PropTypes.string,
  translations: PropTypes.object,
  isEditor: PropTypes.bool,
  createdWithResponsiveEditor: PropTypes.bool,
  useMobileDesign: PropTypes.bool,
  padding: PropTypes.number,
};

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  isEditor: isEditor(state),
  createdWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
  useMobileDesign: getUseMobileDesignSettings(state),
  padding:
    (getIsMobile(state) && !getUseMobileLayoutSettings(state)) ||
    getIsCreatedWithResponsiveEditor(state)
      ? 0
      : getLayoutMargins({
          state,
          section: SECTION_POST_LIST,
          layoutType: getLayoutType(state, SECTION_POST_LIST, getUseMobileLayoutSettings(state)),
        }),
});

export default flowRight(withTranslation(), withReduxStore, connect(mapRuntimeToProps))(AppRoot);
